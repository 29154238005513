<template>
    <div
        class="menu-fixed menu-shadow d-xl-none side-show-xl menu-light sidebar-md-sm-show"
        style="top: 0px; z-index: 999; background: #181e38 !important; left: 0px; position: fixed; height: 100%; margin-top: 180px; width: 60px;"
    >
        <ul class="ps-container main-menu-content scroll-area ps" style="margin-top: 20px;  padding-left: 22px !important;">
            <ul class="navigation-main ul-nav-tablet-exp" style="color: rgb(255, 255, 255); font-size: 16px; font-weight: 300; font-style: normal;">
                <li class="nav-item nav-tablet-exp" @click.stop.prevent="($route.name === 'shared-site' || $route.name === 'contact-invited') ? openRegister() : null">
                    <b-link
                        class="d-flex align-items-center"
                        :to="{name: 'sites-no-project'}"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32.8 27.552">
                            <g id="i-my-sites" transform="translate(1120.4 -99.1)">
                                <path id="Trazado_672" data-name="Trazado 672" d="M-1117.5,126.252a2.307,2.307,0,0,1-2.5-2.044V101.544a2.307,2.307,0,0,1,2.5-2.044h27.008a2.307,2.307,0,0,1,2.5,2.044v22.664a2.307,2.307,0,0,1-2.5,2.044Zm-1.344-2.044a1.277,1.277,0,0,0,1.344,1.193h27.008a1.277,1.277,0,0,0,1.344-1.193V106.826h-29.7Zm1.344-23.857a1.277,1.277,0,0,0-1.344,1.193v4.431h29.7v-4.431a1.277,1.277,0,0,0-1.344-1.193Z" transform="translate(0 0)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></path>
                                <path id="Trazado_673" data-name="Trazado 673" d="M-1058.424,256.972c-.312,0-.576-.195-.576-.425V251.69c0-.231.264-.425.576-.425h23.039c.313,0,.577.195.577.425v4.856c0,.231-.264.425-.577.425Zm.576-.851h21.888v-4.006h-21.888Z" transform="translate(-57.096 -142.052)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></path>
                                <path id="Trazado_674" data-name="Trazado 674" d="M-1058.424,383.442c-.312,0-.576-.195-.576-.425V378.16c0-.231.264-.425.576-.425h9.6c.312,0,.576.195.576.425v4.856c0,.231-.264.425-.576.425Zm.576-.851h8.448v-4.006h-8.448Z" transform="translate(-57.096 -260.428)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></path>
                                <circle id="Elipse_34" data-name="Elipse 34" cx="0.576" cy="0.576" r="0.576" transform="translate(-1116.096 103.34)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></circle>
                                <circle id="Elipse_35" data-name="Elipse 35" cx="0.576" cy="0.576" r="0.576" transform="translate(-1112.256 103.34)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></circle>
                                <circle id="Elipse_36" data-name="Elipse 36" cx="0.576" cy="0.576" r="0.576" transform="translate(-1108.416 103.34)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></circle>
                                <path id="Trazado_675" data-name="Trazado 675" d="M-788.424,160.652a.577.577,0,0,1-.576-.576.577.577,0,0,1,.576-.576h5.76a.577.577,0,0,1,.576.576.577.577,0,0,1-.576.576Z" transform="translate(-309.816 -56.16)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></path>
                                <path id="Trazado_676" data-name="Trazado 676" d="M-848.424,383.442c-.312,0-.576-.195-.576-.425V378.16c0-.231.264-.425.576-.425h9.6c.312,0,.576.195.576.425v4.856c0,.231-.264.425-.576.425Zm.576-.851h8.448v-4.006h-8.448Z" transform="translate(-253.656 -260.428)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"></path>
                            </g>
                        </svg>
                    </b-link>
                </li>
                <li class="nav-item nav-tablet-exp" style="margin-top: 20px;" @click.stop.prevent="($route.name === 'shared-site' || $route.name === 'contact-invited') ? openRegister() : null">
                    <b-link
                        class="d-flex align-items-center"
                        :to="{name: 'projects'}"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path id="i-archive" d="M25.938,7.226l-1.7-2.053A1.774,1.774,0,0,0,22.833,4.5H8.167a1.816,1.816,0,0,0-1.418.672L5.062,7.226A2.393,2.393,0,0,0,4.5,8.778V24.056A2.452,2.452,0,0,0,6.944,26.5H24.056A2.452,2.452,0,0,0,26.5,24.056V8.778A2.393,2.393,0,0,0,25.938,7.226Zm-10.438,15L8.778,15.5h4.278V13.056h4.889V15.5h4.278ZM7.091,6.944l.99-1.222H22.748L23.9,6.944Z" transform="translate(-4.5 -4.5)" fill="#fff"></path>
                        </svg>
                    </b-link>
                </li>
                <li class="nav-item nav-tablet-exp" style="margin-left: 1px; margin-top: 20px;" v-if="($route.name !== 'shared-site' && $route.name !== 'contact-invited')" @click.stop.prevent="($route.name === 'shared-site' || $route.name === 'contact-invited') ? openRegister() : null">
                    <b-link
                        class="d-flex align-items-center"
                        :to="{name: 'memberships'}"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <g id="i-membership" transform="translate(-1.5 -1.5)">
                                <path id="Trazado_667" data-name="Trazado 667" d="M21.41,11.58l-9-9A1.987,1.987,0,0,0,11,2H4A2.006,2.006,0,0,0,2,4v7a2,2,0,0,0,.59,1.42l9,9A1.987,1.987,0,0,0,13,22a1.955,1.955,0,0,0,1.41-.59l7-7A1.955,1.955,0,0,0,22,13,2.02,2.02,0,0,0,21.41,11.58ZM13,20.01,4,11V4h7V3.99l9,9Z" fill="#fff" stroke="#181e38" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path>
                                <circle id="Elipse_32" data-name="Elipse 32" cx="1.5" cy="1.5" r="1.5" transform="translate(5 5)" fill="#fff" stroke="#181e38" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></circle>
                                <path id="Trazado_668" data-name="Trazado 668" d="M8.9,12.55A2.057,2.057,0,0,0,9.5,14L13,17.5,16.5,14a2.051,2.051,0,0,0-2.9-2.9l-.6.6-.6-.59a2.025,2.025,0,0,0-1.45-.61A2.053,2.053,0,0,0,8.9,12.55Z" fill="#fff" stroke="#181e38" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"></path>
                            </g>
                        </svg>
                    </b-link>
                </li>
                <li class="nav-item nav-tablet-exp" style="margin-left: 1px; margin-top: 20px;" @click.stop.prevent="($route.name === 'shared-site' || $route.name === 'contact-invited') ? openRegister() : null">
                    <b-link
                        class="d-flex align-items-center"
                        :to="{name: 'pages-account-setting'}"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="margin-left: 2px; margin-right: 0.9rem;">
                            <g id="i-profile" transform="translate(-59)">
                                <g id="Grupo_284" data-name="Grupo 284" transform="translate(59 11.015)">
                                    <path id="Trazado_677" data-name="Trazado 677" d="M72.289,317.178a.711.711,0,0,1-.711-.711,5.052,5.052,0,0,0-5.046-5.046H65.467a5.052,5.052,0,0,0-5.046,5.046.711.711,0,1,1-1.421,0A6.474,6.474,0,0,1,65.467,310h1.066A6.474,6.474,0,0,1,73,316.467.711.711,0,0,1,72.289,317.178Z" transform="translate(-59 -310)" fill="#fff"></path>
                                </g>
                                <g id="Grupo_285" data-name="Grupo 285" transform="translate(61.132)">
                                    <path id="Trazado_678" data-name="Trazado 678" d="M123.8,9.594a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,123.8,9.594Zm0-8.173A3.376,3.376,0,1,0,127.173,4.8,3.379,3.379,0,0,0,123.8,1.421Z" transform="translate(-119 0)" fill="#fff"></path>
                                </g>
                            </g>
                        </svg>
                    </b-link>
                </li>
                <li class="nav-item nav-tablet-exp" style="margin-left: 1px; margin-top: 20px;">
                    <b-link
                        class="d-flex align-items-center"
                        :to="{name: 'contact-invited'}"
                        v-if="($route.name === 'shared-site' || $route.name === 'contact-invited')"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path id="i-contact" d="M11,23.59v-3.6A9.5,9.5,0,1,1,21,10.5c0,4.95-3.44,9.93-8.57,12.4ZM11.5,3a7.5,7.5,0,0,0,0,15H13v2.3c3.64-2.3,6-6.08,6-9.8A7.5,7.5,0,0,0,11.5,3Zm-1,11.5h2v2h-2Zm2-1.5h-2c0-3.25,3-3,3-5a2,2,0,0,0-4,0h-2a4,4,0,0,1,8,0C15.5,10.5,12.5,10.75,12.5,13Z" transform="translate(-1.75 -0.75)" fill="#fff" stroke="#181e38" stroke-width="0.5"></path>
                        </svg>
                    </b-link>
                    <b-link
                        class="d-flex align-items-center"
                        :to="{name: 'contact'}"
                        v-else
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path id="i-contact" d="M11,23.59v-3.6A9.5,9.5,0,1,1,21,10.5c0,4.95-3.44,9.93-8.57,12.4ZM11.5,3a7.5,7.5,0,0,0,0,15H13v2.3c3.64-2.3,6-6.08,6-9.8A7.5,7.5,0,0,0,11.5,3Zm-1,11.5h2v2h-2Zm2-1.5h-2c0-3.25,3-3,3-5a2,2,0,0,0-4,0h-2a4,4,0,0,1,8,0C15.5,10.5,12.5,10.75,12.5,13Z" transform="translate(-1.75 -0.75)" fill="#fff" stroke="#181e38" stroke-width="0.5"></path>
                        </svg>
                    </b-link>
                </li>
            </ul>
        </ul>
    </div>
</template>

<script>
import {
    BLink,
} from 'bootstrap-vue'

export default {
    components: {
        BLink,
    },
    methods: {
        openRegister() {
            // 
        }
    }
}
</script>

<style>
.ul-nav-tablet-exp {
    list-style: none;
    padding-left: 0px !important;
}
@media (max-width: 1199px) {
    .sidebar-md-sm-show {
        margin-top: 280px !important;
    }
}
/* .nav-tablet-exp::marker {
  display: block !important;
  content: "";
  list-style: none;
} */
</style>