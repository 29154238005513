<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    :style="$route.name === 'view-errors' || $route.name === 'shared-site' ? 'max-width: 2053px; margin: auto; min-height: 180px !important;' : 'max-width: 2053px; min-height: 180px !important; margin: auto;'"
  >
    <register-sidebar :show-open-register-sidebar.sync="showOpenRegisterSidebar" />
    <add-site-project :show-open-add-site-project.sync="showOpenAddSiteProject" :idsite="''" />

    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :style="$route.name === 'view-errors' || $route.name === 'shared-site' ? 'max-width: 2053px; min-height: 180px !important;' : 'max-width: 2053px; min-height: 180px !important;'"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
      :is-nav-clicked.sync="isNavClicked"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
        class="white-background"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
          style="margin-top: 40px !important;"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <slot name="customizer" />

    <div style="position: fixed; right: 10px; bottom: 30px; background: #7838ff30; border-radius: 10px; padding: 16px 7px;" v-if="showNotification">
      <!-- Condicionalmente mostrar la notificación -->
      <div class="notification">
        <p style="margin: 10px 5px 12px; color: #181e38; max-width: 350px;">{{counterEmailSent ? 'Please check your inbox (and spam/junk folder) to complete the verification process.' : 'Your email has not been confirmed'}}</p>
        <button style="background: #181e38; color: #fff; font-weight: 600; border: 1px solid #181e38; margin-left: 10px; margin-bottom: 10px; border-radius: 5px; padding: 3px 7px;" @click="resendVerificationEmail">Send email</button>
        <button style="background: #181e3800; color: #181e38; font-weight: 600; border: 1px solid #181e38; margin-left: 10px; margin-bottom: 10px; border-radius: 5px; padding: 3px 7px;" @click="remindMeLater">{{counterEmailSent ? 'Close' : 'Remind me later'}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddSiteProject from '@/views/extensions/acl/table/sidebar/AddSiteProject.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { db, auth, firebase } from '@/firebase'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    RegisterSidebar,
    AddSiteProject,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      diff: 0,
      isNavClicked: false,
      showOpenRegisterSidebar: false,
      showOpenAddSiteProject: false,
      logoutTimer: null,
      timerLayout: null,

      user: null,
      showNotification: false,
      notificationInterval: null,
      counterEmailSent: 0
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
  mounted() {
    this.isNavClicked = false
    this.showOpenRegisterSidebar = false
    this.showOpenAddSiteProject = false
    const logLastActivity = localStorage.getItem('logActy')
    const fechaInicio = moment(Date.now())
    const fechaFin = moment(parseInt(logLastActivity, 10))
    var diff = fechaInicio.diff(fechaFin, 'minutes')
    if(diff >= 1440 && this.$route.name !== 'shared-site' && this.$route.name !== 'contact-invited') {
      this.logoutUser()
    } 

    setTimeout(() => {
      if(this.$route.name !== 'shared-site' && this.$route.name !== 'contact-invited') {
        const logLastLogin = localStorage.getItem('ll')
        const fechaInicioActual = moment(Date.now())
        // console.log(logLastLogin);
        if(logLastLogin) {
          const fechaFinLL = moment(parseInt(logLastLogin, 10))
          var diffLastLogin = fechaInicioActual.diff(fechaFinLL, 'minutes')
          // 24 hrs
          // if(diffLastLogin >= 1440) {
          if(diffLastLogin >= 10080) {
            localStorage.removeItem('ll')
            this.logoutUser()
          }
        } else {
          if(this.$route.name !== 'shared-site' && this.$route.name !== 'contact-invited') {
            this.logoutUser()
          }
        }
      }
    }, 2500);
    

    // this.events.forEach(event => {
    //   window.addEventListener(event, this.resetTimer)
    // })

    // this.setTimers()

    // this.timerLayout = setInterval(() => {
    //   this.getLogData()
    // }, 5000)

    // window.addEventListener('mousemove', this.resetLogoutTimer);
    // window.addEventListener('keypress', this.resetLogoutTimer);
    // window.addEventListener('click', this.resetLogoutTimer);
    // window.addEventListener('scroll', this.resetLogoutTimer);
    // this.events.forEach(event => {
    //   window.addEventListener(event, this.resetLogoutTimer)
    // })
    // this.startLogoutTimer();

    // var logLastActivity = localStorage.getItem('logActy')
    // var fechaInicio = moment(Date.now())
    // var fechaFin = moment(parseInt(logLastActivity, 10))
    // this.diff = fechaInicio.diff(fechaFin, 'minutes')
    // if(diff >= 1) {
      // this.logoutUser()
    // } 

    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer)
    })

    this.setTimers()
  },
  destroyed() {
    // this.events.forEach(event => {
    //   window.removeEventListener(event, this.resetTimer)
    // })

    // this.setTimers()
    // clearInterval(this.timerLayout)

    // clearTimeout(this.logoutTimer);
    // this.events.forEach(event => {
    //   window.removeEventListener(event, this.resetLogoutTimer)
    // })

    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer)
    })

    this.setTimers()
  },
  watch: {
    isNavClicked(val) {
      const user = auth.currentUser
      // console.log('open register')
      if(user && val) {
        this.showOpenAddSiteProject = true
      } else if(val) {
        this.showOpenRegisterSidebar = true
      }
    },
    showOpenRegisterSidebar(val) {
      // console.log(val)
      this.isNavClicked = val
    },
    showOpenAddSiteProject(val) {
      this.isNavClicked = val
    }
  },
  methods: {
    ...mapActions(['cerrarSesion']),
    setTimers() {
      // var logLastActivity = localStorage.getItem('logActy')
      // this.logoutTimer = setTimeout(this.logoutUser, 1 * 60 * 1000)
      this.logoutTimer = setTimeout(this.logoutUser, 24 * 60 * 60 * 1000);
      // console.log(this.diff,this.logoutTimer)
    },
    logoutUser() {
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      this.cerrarSesion()
    },
    resetTimer() {
      localStorage.setItem('logActy', Date.now())
      clearTimeout(this.logoutTimer)

      this.setTimers()
    },

    // Verificar si el correo está confirmado
    async checkUserEmailVerification() {
      // console.log('object');
      firebase.auth().onAuthStateChanged((user) => {
        // console.log(user);
        if(user) {
          this.user = user;
          this.checkAndNotify();
          // Verificar cada hora
          this.notificationInterval = setInterval(() => {
            this.checkAndNotify();
          }, 3600000); // 1 hora en milisegundos
        }
      });
    },
    checkAndNotify() {
      if(this.user && !this.user.emailVerified) {
        // Obtener el último tiempo en que se mostró la notificación desde localStorage
        const forgetNotification = localStorage.getItem('forgetNotification', true)
        if(forgetNotification) {
          const lastNotificationTimeAlready = localStorage.getItem('lastNotificationTimeSend');
          const lastNotificationTime = localStorage.getItem('lastNotificationTime');
          const currentTime = new Date().getTime();

          // Mostrar notificación solo si ha pasado más de una hora
          if(lastNotificationTimeAlready) {
            this.counterEmailSent = 1
            this.showNotification = true;
            localStorage.setItem('lastNotificationTime', currentTime); // Actualizar el tiempo de notificación
            localStorage.setItem('forgetNotification', true)
          } else if(!lastNotificationTime || currentTime - lastNotificationTime >= 3600000) {
            this.showNotification = true;
            localStorage.setItem('lastNotificationTime', currentTime); // Actualizar el tiempo de notificación
            localStorage.setItem('forgetNotification', true)
          } else {
            const forgetNotification = localStorage.getItem('forgetNotification');
            if(forgetNotification) {
              this.showNotification = true;
            }
          }
        } else {
          localStorage.removeItem('forgetNotification')
          this.showNotification = false
        }
      } else {
        if(this.user.emailVerified) {
          localStorage.removeItem('forgetNotification')
        }
        this.showNotification = false; // No mostrar si el correo está verificado
      }
    },
    remindMeLater() {
      localStorage.removeItem('lastNotificationTime')
      localStorage.removeItem('forgetNotification')
      this.showNotification = false
    },
    // Reenviar el correo de verificación
    async resendVerificationEmail() {
      if(this.user) {
        try {
          await auth.currentUser.sendEmailVerification()
          const currentTime = new Date().getTime();
          localStorage.setItem('lastNotificationTimeSend', currentTime)
          // await this.user.sendEmailVerification();
          // alert('Correo de verificación reenviado.');
          this.counterEmailSent += 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email verification Sent',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          // localStorage.removeItem('forgetNotification')
          // this.showNotification = false
        } catch (error) {
          // console.error('Error al reenviar correo:', error);
          // this.showNotification = false

          const currentTime = new Date().getTime();
          localStorage.setItem('lastNotificationTimeSend', currentTime)
          this.counterEmailSent += 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email verification Sent',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }
    },
  },
  created() {
    const user = auth.currentUser
    if(user) {
      this.checkUserEmailVerification()
      //     this.logoutUser()
    }
  }
}
</script>

<style>
.white-background {
  background: #ffffff !important;
}
.modal .modal-header .close {
  transform: none;
  box-shadow: none;
  border-radius: unset;
  background: none;
}
.modal .modal-header .close:hover {
  transform: translate(2px, 2px);
}
.vs--searchable .vs__dropdown-toggle {
  max-height: fit-content !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
